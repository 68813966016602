<template>
  <footer>
      <div class="footer_div">
        <div v-for="(column, index) in footerColumns" :key="index" class="footer_div_span">
          <ul>
            <li v-for="(link, linkIndex) in column" :key="linkIndex">
              <a v-if="link.href" :href="link.href" target="_blank" rel="noopener noreferrer" class="footer-link">
                {{ link.text }}
              </a>
              <span v-else>{{ link.text }}</span>
            </li>
          </ul>
        </div>
      </div>
  </footer>
</template>
<script>
export default {
  name: 'VoiceFooter',
  data() {
    return {
      footerColumns: [
        [
          { href: "https://tiktokvoice.net", text: "tiktokvoice.net" },
          { href: "https://woy.ai", text: "woy.ai" },
          { href: "https://bai.tools", text: "BAI.tools" },
          { href: "https://ai-findr.com/", text: "AI Findr Tools" },
          { href: "https://www.info35.com/", text: "商娱网" },
        ],
        [
          { href: "https://aiwith.me", text: "AI WITH.ME" },
          { href: "https://seektop.ai", text: "SeekTop.ai" },
          { href: "https://allinai.tools", text: "All in AI" },
          { href: "https://www.aitoolist.com/", text: "AITOOLIST" },
          { href: "https://aipure.ai/", text: "AIPURE AI" },
          { href: "https://m.twitch.tv/iambenjamin2037/home", text: "twitch" },
        ],
        [
          { href: "https://aitoolcenter.com/", text: "AI Tool Center" },
          { href: "https://freeaitool.ai/", text: "Free AI Tool" },
          { href: "https://ainavigationsite.com/", text: "AI Navigation Site Tools " },
          { href: "https://aistage.net", text: "AIStage" },
          { href: "https://bento.me/aurthur2027", text: "bento" },
        ],
        [
          { href: "/privacy-policy.html", text: "Privacy Policy" },
          { href: "/terms-of-service.html", text: "Terms of Service" },
          { text: this.$i18n.t('comm.footer_help') },
          { href: this.sendmail, text: this.$i18n.t('comm.footer_mail') },
          { text: "© 2024 TikTok Voice" },
        ]
      ]
    }
  },
  computed: {
    sendmail() {
      return `mailto:${this.$i18n.t('comm.footer_mail')}`
    }
  }
}
</script>
<style scoped>
</style>
